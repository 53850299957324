<template>
  <div>
    <base-wrapper :loading="loading">
      <div class="space-y-4">
        <base-card
          title="Detail Pengiriman"
          description="Detail pengiriman ke Kantor Pelayanan"
        >
          <div class="grid grid-cols-3 gap-6 border-t pt-5">
            <div>
              <dt class="text-xs text-gray-700">No Pengemasan</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockMovement.data.attributes.code }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">No Faktur</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                <p v-if="order">
                  {{ order.attributes.origin_code }} /
                  {{ order.attributes.destination_code }}
                </p>
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">Tanggal Pengemasan</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockMovement.data.attributes.updatedAt | formatDate }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">Kode Kantor Pelayanan</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{
                  destinationOffice ? destinationOffice.attributes.code : '-'
                }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">Nama Kantor Pelayanan</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{
                  destinationOffice ? destinationOffice.attributes.name : '-'
                }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">Alamat Kantor Pelayanan 1</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{
                  destinationOffice ? destinationOffice.attributes.address : '-'
                }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">Alamat Kantor Pelayanan 2</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{
                  destinationOffice
                    ? destinationOffice.attributes.address2
                    : '-'
                }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">
                Nomor Telepon Kantor Pelayanan
              </dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{
                  destinationOffice ? destinationOffice.attributes.phone : '-'
                }}
              </dd>
            </div>
            <div>
              <dt class="text-xs text-gray-700">Berat</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                <span v-if="order">
                  {{ order.attributes.grand_total_weight | toCurrency }}
                </span>
              </dd>
            </div>
          </div>
        </base-card>

        <base-card
          title="Informasi Kemasan"
          description="Daftar kemasan yang akan dikirim ke Kantor Pelayanan"
        >
          <div class="grid grid-cols-3 gap-6 border-t pt-5">
            <base-input
              class="col-span-full"
              inset
              disabled
              label="Nama Kemasan"
              :value="stockMovement.data.attributes.code"
            />

            <datatable class="col-span-full" :columns="packetColumns">
              <template #tbody="{ classes }">
                <tr
                  v-for="packet in stockMovement.data.attributes.packets"
                  :key="packet.uuid"
                  :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
                  @click="onClickDetailKoli(packet)"
                >
                  <td :class="classes.td">
                    <p class="font-medium text-gray-900">{{ packet.name }}</p>
                  </td>
                  <td :class="classes.td">{{ packet.products.length }}</td>
                  <td :class="classes.td">
                    <button>
                      <Icon
                        icon="heroicons:printer-solid"
                        class="h-4 w-4 text-gray-900"
                      />
                    </button>
                  </td>
                </tr>
              </template>
            </datatable>
          </div>
        </base-card>

        <base-card
          v-if="stockMovement.data.attributes.is_shipped"
          title="Informasi Pengeluaran"
          description="Daftar pengeluaran yang akan dikirim ke Kantor Pelayanan"
        >
          <div class="grid grid-cols-3 gap-6 border-t pt-5">
            <base-input
              v-if="order.attributes.order_shipment === 'delivery'"
              class="col-span-full"
              inset
              label="Nomor Resi"
              placeholder="Masukkan nomor resi"
              type="text"
              v-model="stockMovement.data.attributes.airwaybill_number"
              @change="onUpdateBillNumber"
            />
            <base-input
              v-else
              class="col-span-full"
              inset
              disabled
              label="Diambil Oleh"
              :value="stockMovement.data.attributes.taken_by ?? '-'"
            />
          </div>
        </base-card>

        <div class="flex justify-between">
          <div>
            <base-button
              v-if="!stockMovement.data.attributes.is_shipped"
              color="white"
              @click="cancelConfirmationVisible = true"
            >
              <Icon icon="heroicons:x-mark-solid" class="h-4 w-4" />
              Batalkan Faktur Kemasan
            </base-button>
          </div>
          <div class="flex gap-2">
            <base-button
              v-if="!stockMovement.data.attributes.is_shipped"
              @click="sendModalVisible = true"
            >
              <Icon icon="heroicons:truck-solid" class="h-4 w-4" />
              Kirim
            </base-button>
            <base-button color="white" :to="indexPath">
              <Icon icon="heroicons:arrow-uturn-left-solid" class="h-4 w-4" />
              Kembali
            </base-button>
          </div>
        </div>
      </div>

      <view-packet-modal
        :with-complain-form="false"
        :packet="detailKoliModal.packet"
        :visible="detailKoliModal.visible"
        @close="detailKoliModal.visible = false"
      />
      <cancel-shipment-confirmation
        :visible="cancelConfirmationVisible"
        :stock-movement-id="stockMovement.data.id"
        @close="cancelConfirmationVisible = false"
        @confirmed="$router.push(indexPath)"
      />
      <send-stock-movement-modal
        :visible="sendModalVisible"
        :stock-movement="stockMovement"
        @close="sendModalVisible = false"
        @sent="$router.push(indexPath)"
      />
    </base-wrapper>
  </div>
</template>

<script>
import ViewPacketModal from '@/components/stock-movement/view-packet-modal.vue';
import CancelShipmentConfirmation from '@/components/stock-movement/cancel-shipment-confirmation.vue';
import { requestMixin } from '@/mixins/request/request';
import SendStockMovementModal from '@/components/stock-movement/send-stock-movement-modal.vue';

export default {
  components: {
    ViewPacketModal,
    CancelShipmentConfirmation,
    SendStockMovementModal,
  },
  mixins: [requestMixin],
  data() {
    return {
      indexPath: {
        name: 'gudang.pengiriman.barang-ke-kp',
      },
      detailKoliModal: {
        visible: false,
        packet: {},
      },
      cancelConfirmationVisible: false,
      sendModalVisible: false,
      loading: false,
      stockMovement: {
        data: {
          attributes: {},
          relationships: {
            order: {
              data: {},
            },
            'destination-office': {
              data: {},
            },
          },
        },
        included: [],
      },
    };
  },
  computed: {
    destinationOffice() {
      return this.getSingleIncluded(
        this.stockMovement,
        this.stockMovement.data.relationships['destination-office'].data.id
      );
    },
    order() {
      return this.getSingleIncluded(
        this.stockMovement,
        this.stockMovement.data.relationships.order.data.id
      );
    },
    packetColumns() {
      return [
        {
          id: 'code',
          name: 'Kode Koli',
        },
        {
          id: 'qty',
          name: 'Jumlah Produk',
        },
        {
          id: 'action',
          name: 'Aksi',
        },
      ];
    },
  },
  methods: {
    async loadStockMovement() {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/stock-movements/${this.$route.params.id}`,
        {
          params: {
            include: 'order,destination-office',
            'fields[stock-movements]':
              'code,updatedAt,is_shipped,airwaybill_number,taken_by,packets,order,destination-office,shipment_type',
            'fields[orders]':
              'origin_code,destination_code,order_shipment,grand_total_weight',
            'fields[offices]': 'code,name,address,address2,phone',
          },
        }
      );

      if (!err) {
        this.stockMovement = res;
      }

      this.loading = false;
    },
    onClickDetailKoli(packet) {
      this.detailKoliModal.packet = packet;
      this.detailKoliModal.visible = true;
    },
    async onUpdateBillNumber() {
      this.loading = true;

      await this.request(`/api/v1/stock-movements/${this.$route.params.id}`, {
        method: 'patch',
        data: {
          data: {
            type: 'stock-movements',
            id: this.$route.params.id,
            attributes: {
              airwaybill_number:
                this.stockMovement.data.attributes.airwaybill_number,
            },
          },
        },
      });

      this.loading = false;
    },
  },
  created() {
    this.loadStockMovement();
  },
};
</script>
